import {
  ArrowDownTrayIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Spinner from "@sid/core/components/icon/Spinner";
import assetUrl from "@sid/core/vars/assetUrl";
import clsx from "clsx";
import { toPng } from "html-to-image";
import { Trans } from "next-i18next";
import dynamic from "next/dynamic";
import QRCode from "qrcode.react";
import { useEffect, useMemo, useRef, useState } from "react";
import QRCodeStyling from "styled-qr-code";
import useSWR from "swr";
import Button from "./Button";
import ModalComponent, { useModal } from "./Modal";
import QRCodeManager from "./QRCodeManager";

const QRRender = dynamic(() => import("@sid/core/components/QRRender"), {
  ssr: false,
});

type Props = {
  url: string;
  withEdit?: boolean;
  withQREdit?: boolean;
  disableDownload?: boolean;
  linkId?: any;
  onChoose?(): any;
  qrOnly?: boolean;
  id?: string;
};

const qrcodeBaseWidth = 400;

export const useDownloadQR = () => {
  return {
    downloadQR: async (id) => {
      const qrCanvas: any = document.querySelector(`#${id || "qr-result"}`);

      if (!qrCanvas) {
        return;
      }

      const dataUrl = await toPng(qrCanvas, {
        cacheBust: true,
        pixelRatio: 2,
      });

      const link = document.createElement("a");
      link.download = "qr-" + Math.round(new Date().getTime() / 1000) + ".png";
      link.href = dataUrl;
      link.click();
      link.remove();
    },
  };
};

const QRCodeShare = ({
  url,
  linkId,
  withQREdit,
  qrOnly = false,
  id,
}: Props) => {
  const changeModal = useModal();
  const qrRef = useRef<QRCodeStyling>();
  const qrURL = useMemo(() => {
    const _url = new URL(url);
    _url.searchParams.set("s", "qr");
    return _url.toString();
  }, [url]);

  const qrData = useSWR(() => (linkId ? `/links/${linkId}/qrcode` : null));
  const isLoading = !qrData.data && qrData.isValidating;

  const downloadQrCode = async () => {
    if (isLoading) return;

    const _url = new URL(url);
    const [, slug] = _url.pathname.split("/");

    qrRef.current?.download({
      name: "qr-" + slug,
      extension: "png",
    });
  };

  const data = useMemo(() => {
    return qrData.data?.data || {};
  }, [qrData]);

  return (
    <>
      <div className="mt-2 flex justify-center flex-col md:flex-row">
        <div className="flex items-center flex-col md:flex justify-center">
          <div className="flex items-center justify-center">
            <div className="!max-w-[200px]">
              <QRRender
                url={qrURL}
                id={id}
                qrRef={(r) => (qrRef.current = r)}
                data={data}
                isLoading={isLoading}
              />
            </div>
          </div>
          {!qrOnly && (
            <>
              <h2 className="mt-4 mb-2 text-sm text-gray-500 dark:text-gray-200">
                {url}
              </h2>
              <div className="flex gap-2">
                <Button
                  color="primary"
                  className="mt-2 text-sm flex items-center"
                  size="sm"
                  disabled={isLoading}
                  onClick={downloadQrCode}
                >
                  <ArrowDownTrayIcon className="w-4 mr-1" />
                  Download
                </Button>
                {withQREdit && (
                  <Button
                    color="secondary"
                    className="mt-2 text-sm flex items-center"
                    size="sm"
                    disabled={isLoading}
                    onClick={() => {
                      if (isLoading) return;
                      changeModal.setOpen(true);
                    }}
                  >
                    <PencilSquareIcon className="inline-block w-4 mr-1" />
                    <Trans i18nKey="dashboard:change">Change</Trans>
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <ModalComponent {...changeModal} disabled className="!max-w-[590px]">
        <div className="flex">
          <h1 className="text-xl font-montserrat font-semibold flex-1">
            <Trans i18nKey="dashboard:change_qrcode_style">
              Change QR Code Style
            </Trans>
          </h1>
          <button onClick={changeModal.close}>
            <XMarkIcon className="w-7" />
          </button>
        </div>
        <div className="w-full h-[350px] bg-gray-100 dark:bg-gray-800 rounded-md mt-3 overflow-x-hidden overflow-y-auto sid-scrollbar p-2">
          <QRCodeManager
            chooseOnly
            onChoose={() => {
              qrData.mutate();
            }}
            linkId={linkId}
          />
        </div>
      </ModalComponent>
    </>
  );
};

interface RenderQRAndLogo {
  url: string;
  isLoading: boolean;
  logo?: string;
  active?: boolean;
  color?: string;
  id?: string;
  newLogo?: boolean;
  className?: string;
  fromBuffer?: boolean;
}

export const RenderQRAndLogo = ({
  url,
  isLoading = false,
  logo: _logo,
  active,
  color,
  id,
  newLogo,
  className,
  fromBuffer,
}: RenderQRAndLogo) => {
  // const { values } = useFormikContext<any>();
  const [logo, setLogo] = useState<string>("");
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [dimen, setDimen] = useState({ w: 164, h: 164 });

  // Just did a hack since the original plugin doesn't have crossOrigin feature
  useEffect(() => {
    let isDefault = true;
    let isNewLogo = newLogo;

    //sid-logo-23.svg

    // let imageUrl = assetUrl + "new-qr-brand.png";
    let imageUrl = assetUrl + "sid-logo-23_2.svg";

    if (isNewLogo) {
      isNewLogo = true;
      imageUrl = assetUrl + "sid-s-01.svg";
    }

    if (_logo?.length) {
      isDefault = false;
      imageUrl = fromBuffer ? _logo : assetUrl + _logo;
    }
    setLogoLoaded(false);
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        canvas.height = image.naturalHeight || 0;
        canvas.width = image.naturalWidth || 0;
        const maxD = isDefault ? (isNewLogo ? 124 : 150) : 120;
        let tw = maxD;
        let th = maxD;
        if (canvas.width > canvas.height) {
          th = (canvas.height / canvas.width) * maxD;
        } else {
          tw = (canvas.width / canvas.height) * maxD;
        }

        setDimen({ w: tw, h: th });

        context.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL();
        setLogo(dataURL);
        setLogoLoaded(true);
      }
    };
    image.crossOrigin = "Anonymous";
    image.src = imageUrl;
  }, [_logo, newLogo, fromBuffer]);

  return (
    <div
      className={clsx("bg-white p-3 rounded-lg shadow-md w-full", className)}
      id={id || "qr-result"}
    >
      {(active && logo && !logoLoaded) || isLoading ? (
        <div className="pb-[100%] relative w-[200px]">
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-gray-800">
            <Spinner className="w-10 animate-spin mb-3" />
            <p>Loading...</p>
          </div>
        </div>
      ) : (
        <QRCode
          value={url}
          renderAs="canvas"
          size={qrcodeBaseWidth}
          fgColor={color || "#000"}
          style={{ maxWidth: 200, maxHeight: 200 }}
          level={"H"}
          imageSettings={
            active
              ? {
                  src: logo,
                  x: null,
                  y: null,
                  width: dimen.w,
                  height: dimen.h,
                  excavate: true,
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

export default QRCodeShare;
