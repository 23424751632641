import { Float } from "@headlessui-float/react";
import { Menu } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, ReactNode } from "react";

interface IDropdownMenu {
  children: ReactNode;
  as?: any;
  head: ReactNode;
  disabled?: boolean;
  className?: string;
  classNameActive?: string;
  headClassName?: string;
  itemClassName?: string;
}

const Dropdown = {
  Menu: ({
    children,
    head,
    disabled = false,
    as = "button",
    className,
    classNameActive = "",
    itemClassName,
  }: IDropdownMenu) => {
    return (
      <Menu>
        {({ open }) => (
          <Float
            placement="bottom-end"
            transform={false}
            portal
            flip
            // floatingAs={Fragment}
            tailwindcssOriginClass
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Button
              disabled={disabled}
              as={as}
              className={clsx(className, {
                [classNameActive]: open,
              })}
            >
              {head}
            </Menu.Button>
            <Menu.Items
              className={clsx(
                "shadow-md bg-white dark:bg-gray-800",
                "rounded-md overflow-hidden overflow-y-auto max-h-[300px] sid-scrollbar",
                itemClassName
              )}
            >
              {children}
            </Menu.Items>
          </Float>
        )}
      </Menu>
    );
  },
  Item: ({ children, ...props }) => {
    return (
      <Menu.Item>
        <button
          {...props}
          className={clsx(
            "transition-colors whitespace-nowrap",
            "px-4 py-2 w-full block text-left hover:bg-blue-600 hover:text-white font-medium dark:text-white",
            !!props.icon && "flex items-center",
            props.className
          )}
        >
          {props.icon ? (
            <div className="w-5 mr-2">{props.icon}</div>
          ) : (
            <Fragment />
          )}
          {children}
        </button>
      </Menu.Item>
    );
  },
  Divider: () => {
    return <div className="border-b dark:border-gray-700 my-3 mx-2" />;
  },

  Heading: ({ children }) => {
    return (
      <div className="text-xs font-semibold uppercase text-gray-500 dark:text-gray-400 my-3 mx-2">
        {children}
      </div>
    );
  },
};

export default Dropdown;
