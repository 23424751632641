import {
  ArrowTopRightOnSquareIcon,
  ClipboardIcon,
  PlusIcon,
  QrCodeIcon,
  ShareIcon,
} from "@heroicons/react/24/solid";
import Dropdown from "@sid/core/components/Dropdown";
import { assetPrefix } from "@sid/core/vars/assetUrl";
import clsx from "clsx";
import { Trans, useTranslation } from "next-i18next";
import Image from "next/image";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { useCopyToClipboard } from "react-use";
import ModalComponent, { ModalHeader, useModal } from "./Modal";
import QRCodeShare from "./QRCodeShare";

interface Props {
  url: string;
  color?: string;
  size?: string;
  rounded?: boolean;
  simple?: boolean;
  withCopy?: boolean;
  withQREdit?: boolean;
  linkId?: any;
  withVisitMicrosite?: any;
  onChoose?(): any;
  className?: string;
}

const ShareButton = ({
  url,
  color,
  size = "sm",
  rounded = false,
  simple = false,
  withCopy = false,
  withQREdit,
  linkId,
  onChoose,
  withVisitMicrosite = false,
  className = "",
}: Props) => {
  const { t } = useTranslation();
  const qrModal = useModal();
  const [, copyCipboard] = useCopyToClipboard();

  const menus = [
    {
      label: t("visit_microsite"),
      slug: "visit_microsite",
      icon: <ArrowTopRightOnSquareIcon className="w-5 mr-1" />,
    },
    {
      label: "Facebook",
      slug: "facebook",
      icon: "facebook-icon.svg",
    },
    {
      label: "Twitter X",
      slug: "twitter",
      icon: "twitter-logo-x.svg",
    },
    {
      label: "WhatsApp",
      slug: "whatsapp",
      icon: "whatsapp-icon.svg",
    },
    {
      label: "Copy",
      slug: "copy",
      icon: <ClipboardIcon className="w-5 mr-1" />,
    },
    {
      label: "QR Code",
      slug: "qrcode",
      icon: <QrCodeIcon className="w-5 mr-1" />,
    },
    {
      label: "Plus URL",
      slug: "plus",
      icon: <PlusIcon className="w-5 mr-1" />,
    },
  ];

  const createPopup = (url, name) => {
    const winHeight = 400;
    const winWidth = 600;
    const winTop = screen.height / 2 - winHeight / 2;
    const winLeft = screen.width / 2 - winWidth / 2;
    window.open(
      url,
      name,
      "top=" +
        winTop +
        ",left=" +
        winLeft +
        ",toolbar=0,status=0,width=" +
        winWidth +
        ",height=" +
        winHeight
    );
  };

  const onShare = (item) => {
    const safeUrl = encodeURIComponent(url);

    if (item.slug === "visit_microsite") {
      window.open(url, "_blank");
      return;
    }

    if (item.slug === "copy") {
      copyCipboard(url);
      toast.success(t("success_copy_clipboard"));
    }

    if (item.slug === "plus") {
      copyCipboard(url + "+");
      toast.success(t("success_copy_clipboard"));
    }

    if (item.slug === "facebook") {
      createPopup(
        `http://www.facebook.com/sharer.php?u=${safeUrl}&t=${safeUrl}`,
        item.label
      );
    }

    if (item.slug === "twitter") {
      createPopup(
        `https://twitter.com/intent/tweet?text=${safeUrl}`,
        item.label
      );
    }

    if (item.slug === "whatsapp") {
      createPopup(`https://wa.me?text=${safeUrl}`, item.label);
    }

    if (item.slug === "qrcode") {
      qrModal.setOpen(true);
    }
  };

  return (
    <Fragment>
      <Dropdown.Menu
        className={clsx(
          "flex appearance-none items-center transition-all",
          "border ",
          rounded ? "rounded-full" : "rounded-md",
          size === "base" && "px-3 py-2",
          size === "sm" && "py-1 px-2 text-sm",
          className,
          color === "blue"
            ? [
                "text-white",
                "bg-blue-600 hover:bg-blue-700 ",
                "border-blue-600",
              ]
            : color === "green"
            ? [
                "text-white",
                "bg-green-600 hover:bg-green-700 ",
                "border-green-600",
              ]
            : [
                "dark:text-white",
                "bg-gray-100 hover:bg-gray-200 ",
                "border-gray-100 dark:border-gray-800",
                "dark:bg-gray-800 dark:hover:bg-gray-700",
              ]
        )}
        head={
          <Fragment>
            <span className="w-4">
              <ShareIcon />
            </span>
            {!simple ? (
              <>
                <span className="hidden lg:inline-block ml-2">
                  {t("share")}
                </span>
                <span className="lg:hidden -ml-1">&nbsp;</span>
              </>
            ) : (
              <span className="-ml-1">&nbsp;</span>
            )}
          </Fragment>
        }
      >
        {menus
          .filter((m) => {
            if (!withVisitMicrosite && m.slug === "visit_microsite") {
              return false;
            }
            return m;
          })
          .filter((m) => {
            if (!withCopy && m.slug === "copy") {
              return false;
            }
            return m;
          })
          .map((item) => (
            <Dropdown.Item
              className="transition-all flex duration-100 px-3 py-2 items-center dark:hover:bg-gray-900 text-left font-medium"
              key={item.slug}
              onClick={() => onShare(item)}
            >
              <div>
                {typeof item.icon === "string" ? (
                  <div className="w-6 h-6 relative">
                    <Image
                      src={assetPrefix + "images/" + item.icon}
                      fill
                      alt={item.label}
                    />
                  </div>
                ) : (
                  item.icon
                )}
              </div>
              <div className="pl-3">{item.label}</div>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
      <ModalComponent {...qrModal} className="!max-w-[300px]">
        <ModalHeader {...qrModal}>
          <Trans i18nKey="qrcode_image">QR Code Image</Trans>
        </ModalHeader>
        <div className="w-full relative">
          <QRCodeShare
            url={url}
            onChoose={onChoose}
            withQREdit={withQREdit}
            linkId={linkId}
          />
        </div>
      </ModalComponent>
    </Fragment>
  );
};

export default ShareButton;
